<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-2/4">
        <statistics-card-line hideChart class="mb-base" icon="UserIcon" icon-right :statistic="profile.wallet_name"
          statisticTitle="Name" color="success" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-2/4">
        <statistics-card-line hideChart class="mb-base" icon="PhoneIcon" icon-right :statistic="profile.wallet_phone"
          statisticTitle="Phone" />
      </div>
    </div>
    <flat-pickr class="mb-4 w-1/3" placeholder="Select Date" v-model="date" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="listdata">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ listdata.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : listdata.length }} of
              {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="sms_datetime">Date Time</vs-th>
        <vs-th sort-key="sms_info">Message</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td>
              <p class="product-name font-medium truncate"
                :data="moment(tr.sms_datetime).format('YYYY-MM-DD hh:mm:ss')">
                {{ moment(tr.sms_datetime).tz("Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss') }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category" :data="tr.sms_info">{{ tr.sms_info }}</p>
            </vs-td>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import axios from '../../axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
  components: {
    flatPickr,
    StatisticsCardLine
  },
  data () {
    return {
      listdata: [],
      selected: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      date: this.moment().format('YYYY-MM-DD'),
      data_profile: [],
      profile: {wallet_name: '', wallet_phone: ''}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.listdata.length
    }
  },
  watch: {
    async date (newValue) {
      this.date = newValue
      this.getsmsdata()
    }
  },
  async mounted () {
    this.isMounted = true
    await this.getsmsdata()
    await axios
      .get(`wallet/settingwallet/${this.$route.params.id}`)
      .then(response => (this.data_profile = response.data))
    this.profile.wallet_name = this.data_profile[0].wallet_name
    this.profile.wallet_phone = this.data_profile[0].wallet_phone
  },
  methods: {
    async getsmsdata () {
      await axios
        .get(`wallet/smstruewallet/sms/${this.$route.params.id}/${this.date}`)
        .then(response => (this.listdata = response.data))
    }
  }
}

</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        >span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }

</style>
